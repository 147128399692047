import Link from "next/link";
import { useState, useEffect } from "react";
import Router ,{ useRouter } from "next/router";
import Cookies, { set } from "js-cookie";
import langTrans from "./../components/i18n";
import Logo from "../components/logo";
import { Formik, Form, useFormik} from "formik";
import * as Yup from "yup";
import { login_user, get_user_details } from "./api/users";
import "rsuite/dist/rsuite.min.css";
import TextField from "../components/TextField";
import { parseCookies } from "nookies";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import toast, { Toaster } from 'react-hot-toast';

export default function Login(props) {
  // const [userName, setName] = useState("");
  // const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loginMessage, setloginMessage] = useState("");
  const { locale, locales, defaultLocale, asPath } = useRouter();
  const [language_change, setlanguage_change] = useState('fr')
const [InitialLanguage, setInitialLanguage] = useState(Cookies.get('NEXT_LOCALE') ? Cookies.get('NEXT_LOCALE') : 'fr')
const { login, a_forgot_pass, btn_login,password_required,email_invaild,email_required } = langTrans[InitialLanguage].user;
  const [ToggleEyeOrg, setToggleEyeOrg] = useState(true)
  const [ChangeTextOrg, setChangeTextOrg] = useState('password')

  const router = useRouter();
  let userName = "";
  let password = "";

  const ToggleEyeOriginal =()=>{
    setToggleEyeOrg(!ToggleEyeOrg)
    if(ChangeTextOrg =='password'){
        setChangeTextOrg('text')
        return
    }
    setChangeTextOrg('password')

}
const detectBrowser = () => { 
  if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      return 'Opera';
  } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      return 'Chrome';
  } else if(navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
  } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
      return 'Firefox';
  } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
      return 'IE';//crap
  } else {
      return 'Unknown';
  }
} 

useEffect(() => {

  
  if(Cookies.get("onBoardSuccess")) {
    notify();
    Cookies.remove('onBoardSuccess');
  }
  
}, [])

const notify = () => toast.success("Configuration du compte complèté avec succès!", {
  style: {
      color: 'white',
      textAlign: "center",
      backgroundColor: "#50C262",
      fontSize: "14px",
      padding: "10px 5px"
  },
  duration: 2000
});

const languageShifter =()=>{
  if(InitialLanguage == "fr"){
    // Router.push('/fr')
    setInitialLanguage("en");
  }
  else{
    // Router.push('/en')
    // Router.back('/fr')
    setInitialLanguage("fr");
  }

}

  const validate = Yup.object({
    email: Yup.string().email(email_invaild).required(email_required),
    password: Yup.string()
      .required(password_required),
  });

  /**
   * Login action perform
   *
   * @param {*} 
   */
  const userLogin = async () => {
    console.log("cookies",document.cookie.split(";")[0]);
    const body = {
      language: locale,
      email_id: userName.toLowerCase().trim(),
      password,
      ip:props.ip_address,
      browser : detectBrowser()
    };

    const res2 = await login_user(body);

    if (res2.message && res2.status_code == 403) {
      setloginMessage(res2.message)
      setShowAlert(true)
    }
      
    else if (res2.status_code == 200) {
      Cookies.set("token", res2?.data?.auth_token);
      Cookies.set("role", res2?.data?.user_role);

      // let user = await get_user_details({
      //   api_token: res2?.data?.auth_token,
      //   user_id: res2?.data?.id
      // });
      let data = res2?.data;
      let companyID = null;

      if (data?.user_data?.parent_companies !== undefined && Object.keys(data?.user_data?.parent_companies).length !== 0 ){
        companyID = data?.user_data?.parent_companies._id;
      }
      else if (data?.user_data?.child_companies)
      {
        companyID = data?.user_data?.child_companies[0]._id;
      }

      // companyID.push(comp._id)
      setloginMessage("");
      setShowAlert(false)
      Cookies.set("token", data?.auth_token);
      Cookies.set("role", data?.user_data?.user_role);
      Cookies.set("userid", data?.user_data?._id);
      Cookies.set("name", data?.user_data?.full_name);
      Cookies.set("email", data?.user_data?.email_id);
      Cookies.set("viewed_company_id", companyID ?? data?.user_data?._id );

      //adding if they have access or not to CreditRisk Management
      Cookies.set("crmp_active", data?.isCreditRiskManagementActive);

      {
        data.team_data ?
        Cookies.set("team_access_id", data.team_data._id ) : null
      }
      let filteredUserData = {
                    parent_companies:{
                        _id: data?.user_data?.parent_companies?._id ,
                        company_name : data?.user_data?.parent_companies?.company_name,
                        sub_company_details: data?.user_data?.sub_company_details
                    }

                }
      if (data?.user_data?.user_role == "admin" || data?.user_data?.user_role == "sub-admin") {
        Cookies.set("userData", JSON.stringify(data?.user_data));
      }
      else{
        Cookies.set("userData", JSON.stringify(filteredUserData));
      }
      Cookies.set('NEXT_LOCALE', data?.user_data?.language ?? 'fr')
      //Cookies.set("acceptTermsAndCondiation", data?.user_data?.acceptTermsAndCondiation || false);

      const userRole = data?.user_data?.user_role;

      if( (userRole === 'user'|| userRole === 'user-manager') && !data?.user_data?.acceptTermsAndCondiation) {
        Cookies.set("loginRedirection", true);
        Router.push("/external/confirmation");
      }
      else if (Cookies.get('extraUrl')){
        Router.push(Cookies.get('extraUrl').toString())
      }
      else {
        Router.push("/credit-reports");
      }
     
    } else {
      // setloginMessage("Unknown Login Error.  Please try again.");
      setloginMessage(res2.message);
      setShowAlert(false)
    }
  };


  try {
    return (
      <div className="external_wrapper">
      <div className="login_form">
      <div className="login_wrap">
      <div style={{textAlign:"end"}}><button value={InitialLanguage} onClick={languageShifter} className='lang_btn'>{InitialLanguage == 'en' ? 'Français' : 'English'}</button></div>
        {/* <Lang /> */}
        <Toaster
        position="top-center"
        reverseOrder={false}
    />
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}

          validationSchema={validate}
          onSubmit={userLogin}
        >
          {(formikObj) => {
            
            userName = formikObj.values.email;
            password = formikObj.values.password;
            return (
            <Form> 
              <div>
                <div className="logowrap">
                  <Logo />
                </div>
                <h1 className="mb-2">{login.title}</h1>
                <div className="welcome">{login.welcome}</div>
                <div className="gmsg">{login.message}</div>
                <div style={showAlert ? {display:"initial"}:{display:"none",pointerEvents:"none"}}>
                    <div className={`alert alert-danger alert-dismissible fade error_alert show`} role="alert">
                      <strong>{loginMessage}</strong>
                      <button type="button" className="btn-close" onClick={()=>setShowAlert(false)} aria-label="Close"></button>
                    </div>
                </div>
                <div className="form_wrap">
                  <div className="mb-3">
                    {/* <label htmlFor="username" className="form-label">
                      {login.email}
                    </label> */}
                    {/* <input
                      className="form-control"
                      type="text"
                      id="username"
                      name="email"
                      placeholder="Email Id"
                      value={userName}
                      onChange={(e) => setName(e.target.value.toLowerCase())}
                    /> */}
                    <TextField  type="text"
                      label={login.email}
                      id="username"
                      name="email"
                      placeholder={login.email}
                      
                      />
                        
                  </div>
                  <div className="mb-3" style={{position:"relative"}}>
                    {/* <label htmlFor="password">{login.password}</label> */}
                    {/* <input
                      className="form-control"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    /> */}
                    <span  className='eye_color_login' onClick={ToggleEyeOriginal}>{ToggleEyeOrg ? <AiFillEye size={20} /> : <AiFillEyeInvisible size={20}/>}</span>    
                   <TextField 
                      label={login.password}
                      type={ChangeTextOrg}
                      name="password"
                      id="password"
                      placeholder={login.password}
                      />
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="buttonAlliancePrimary btnlogin mb-3"
                    >
                      {btn_login}
                    </button>
                  </div>
                </div>
                <div onClick={()=>Cookies.set('NEXT_LOCALE',InitialLanguage)} className="center_nav fpass">
                  <Link href="/forgot-password">
                    <a>{a_forgot_pass}</a>
                  </Link>
                </div>
                {/* <div>{loginMessage}</div> */}
              </div>
            </Form>
          )}}
        </Formik>
      </div>
      
      </div>
      </div>
    );
  } catch (ex) {
    return JSON.stringify(ex);
  }
}


export async function getServerSideProps(ctx) {
  // const start = +page === 1 ? 0 : (+page + 1)

  // const { locale, locales, defaultLocale, asPath } = useRouter();
  const { token } = parseCookies(ctx)

  let ip;

  const { req } = ctx;

  if (req.headers["x-forwarded-for"]) {
    ip = req.headers["x-forwarded-for"].split(',')[0]
  } else if (req.headers["x-real-ip"]) {
    ip = req.connection.remoteAddress
  } else {
    ip = req.connection.remoteAddress
  }

  if (token) {
      return {
          redirect: {
            permanent: false,
            destination: "/credit-reports",
          },
          props:{  ip_address : ip},
        };

  }
  return ({ props: {  ip_address : ip} });
}