import Cookies from 'js-cookie'

export const get_user_details = async (body) => {


    return fetch(`${process.env.API_URL}/user/user-details`, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors'
        },
        method: 'POST',
        body: JSON.stringify(body)
    })
        .then(ret => ret.json())
        .catch((ex) =>
            console.log(ex.message)
        )
}
export const get_user_details_user = async (body) => {


    return fetch(`${process.env.API_URL}/user/get-companylist-for-users`, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors'
        },
        method: 'POST',
        body: JSON.stringify(body)
    })
        .then(ret => ret.json())
        .catch((ex) =>
            console.log(ex.message)
        )
}

export const reset_password = ((body) => {
    return fetch(`${process.env.API_URL}/user/reset-password`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    })
        .then(resUser => resUser.json())
})
export const reset_password_token_details = ((body) => {
    return fetch(`${process.env.API_URL}/external/token-details`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    })
        .then(resUser => resUser.json())
})

export const delete_user = (body => {
    return fetch(`${process.env.API_URL}/user/delete-user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(resUser => resUser.json())

})

export const update_user = ((body) => {
    try {
    return fetch(`${process.env.API_URL}/user/update-user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })
        .then(resUser => resUser.json())
    }
    catch (ex) {
        console.log(ex)
    }
})

export const create_sub_admin = ((body) => {
    try {
    return fetch(`${process.env.API_URL}/user/create-sub-admin`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    }).then(data =>
        data.json()
    )
    }
    catch (ex) {
        console.log(ex)
    }
})

export const change_password = ((body) => {
    return fetch(`${process.env.API_URL}/user/change-password`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)

    })
        .then(res => res.json())
})

export const forgot_password = ((body) => {
    return fetch(`${process.env.API_URL}/user/forgot-password`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)

    })
        .then(res => res.json());
})

export const login_user = ((body) => {
    try {
        return fetch(`${process.env.API_URL}/user/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        })
            .then(res =>
                res.json()
            )
    } catch (ex) {
        console.log(ex)
    }
})

export const list_sub_admin = ((body) => {
    return fetch(`${process.env.API_URL}/user/list-sub-admin`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)

    })
        .then(res => res.json())
})

export const list_associate_user = ((body) => {
    return fetch(`${process.env.API_URL}/user/list-associate-user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)

    })
        .then(req => {
            return req.json()
        })
        .then(data => {
            console.log(data)
            return data?.data
        }
        );
})

export const create_user = ((body) => {
    return fetch(`${process.env.API_URL}/user/create-user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    })
        .then(res => res.json())
})

export const list_user = (body) => {
    console.log(body)
    return fetch(`${process.env.API_URL}/user/list-user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)
    })
        .then(res => res.json())
}

export const user_siderbar_details = (body, token) => {
    console.log(body)
    return fetch(`${process.env.API_URL}/user/details-for-edit-user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: token
        },
        body: JSON.stringify(body)
    })
        .then(res => res.json())
}


export const verify_user = ((body) => {
    return fetch(`${process.env.API_URL}/user/update-user-verification-status`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)

    })
        .then(res => res.json())
})

export const simulate_user = ((body) => {
    return fetch(`${process.env.API_URL}/user/simulate-user`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)

    })
        .then(res => res.json())
})

export const set_active=(body=>{
    return fetch(`${process.env.API_URL}/user/update-user-activation-status`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body)

    })
        .then(res => res.json())
})
